import html2pdf from "html2pdf.js";

const defaultOptions = {
    margin: 0,
    // filename: `hehehe.pdf`,
    image: { type: "jpeg", quality: 0.98 },
    enableLinks: true,
    html2canvas: { scale: 1, useCORS: true },
    jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
};

export default async function generatePDFBlob(pdfContent: any, options = {}): Promise<any> {
    const html2PdfSetup = html2pdf()
        .set({ ...defaultOptions, ...options })
        .from(pdfContent);
    const blobUrl = await html2PdfSetup.output("bloburl");
    const res = await fetch(blobUrl);
    const blob = await res.blob();

    return blob;
}
